/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import { Stack, styled, Typography } from '@mui/material';

import Img from '../img';
import LatexFormula from '../latex-formula';

import { mapOldQuestionTypeToNew } from '../../constants/question';

const StyledContainer = styled(Stack)(({ theme, variant, content }) => {
  const defaultMode = variant === 'default'; // preview, test
  const cellMode = variant === 'cell'; // table cell
  const listMode = variant === 'list'; // list
  const testMode = variant === 'test'; // test
  const memoMode = variant === 'memo'; // memo

  const defaultModeFontSize = 16;
  const testModeFontSize = 20;
  const cellModeFontSize = 14;

  return {
    '.sr-only': {
      display: 'none',
    },
    ...(defaultMode && {
      padding: theme.spacing(2),
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: cellMode ? cellModeFontSize : defaultModeFontSize,
      '& .MuiTypography-root': {
        fontSize: cellMode ? cellModeFontSize : defaultModeFontSize,
      },
    }),
    ...(cellMode && {
      padding: theme.spacing(0),
      fontSize: cellMode ? cellModeFontSize : defaultModeFontSize,
      '& .MuiTypography-root': {
        fontSize: cellMode ? cellModeFontSize : defaultModeFontSize,
      },

      '& img': {
        maxHeight: 100,
        width: 'fit-content',
        objectFit: 'contain',
      },
    }),
    ...(listMode && {
      '& .MuiTypography-root': {
        whiteSpace: 'pre-line',
      },
      '& img': {
        maxHeight: 140,
      },
    }),
    ...(testMode && {
      padding: theme.spacing(0),
      '& img': {
        maxHeight: 520,
        minHeight: 400,
        width: 'fit-content',
        margin: '15px auto',
      },
      fontSize: testModeFontSize,
      '& .MuiTypography-root': {
        whiteSpace: 'pre-line',
        fontSize: testModeFontSize,
      },
    }),
    // fix fontSize in cell mode
    ...(content === 'html' && {
      fontSize: defaultModeFontSize,
      ...(testMode && {
        fontSize: testModeFontSize,
      }),
      ...(cellMode && {
        fontSize: cellModeFontSize,
        textAlign: 'left !important',
        '& p': {
          textAlign: 'left !important',
        },
      }),
    }),
    ...(memoMode && {
      padding: theme.spacing(0),
      '& img': {
        maxHeight: 250,
        width: 'fit-content',
      },
    }),
  };
});

export default function Question(props) {
  const question = mapOldQuestionTypeToNew(props.question);

  if (!question) {
    return null;
  }

  const { content } = question;
  const variant = props.variant || 'default';

  let contentNode;

  switch (content.type) {
    case 'text':
      contentNode = (
        <Typography
          {...(variant === 'cell' && {
            variant: 'body2',
          })}
        >
          {content?.data?.text}
        </Typography>
      );
      break;
    case 'image':
      contentNode = <Img src={content?.data?.image} alt="SAT Decoded App Question" />;
      break;
    case 'math':
      contentNode =
        variant === 'cell' ? (
          <LatexFormula formula={content?.data?.formula} />
        ) : (
          <>
            <LatexFormula formula={content?.data?.formula} />
            <Typography>{content?.data?.text}</Typography>
          </>
        );
      break;

    case 'html':
      contentNode = (
        <div
          dangerouslySetInnerHTML={{
            __html: content?.data?.html,
          }}
        />
      );
      break;

    default:
      contentNode = null;
      break;
  }

  return (
    <StyledContainer variant={variant} content={content.type}>
      {contentNode}
    </StyledContainer>
  );
}

Question.propTypes = {
  question: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['default', 'cell', 'list', 'test', 'memo']),
};
