import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

// @mui
import { Container, Box, CircularProgress, Typography, CardContent, Stack, Grid } from '@mui/material';

import { AppContext } from '../../../App';

import { useAxios } from '../../../services/axiosService';

import SATTestHistoryChart from '../../../sections/common/stats/SATTestHistoryChart';

import Ribbon from '../../../sections/common/ribbon';
import TimeSpentToday from '../../../sections/common/stats/TimeSpentToday';
import SubjectsChart from '../../../sections/common/stats/SubjectsChart';
import PracticeHistoryTable from '../../../sections/common/stats/PracticeHistoryTable';

import { formatDuration } from '../../../utils/formatTime';

export default function StudentPortalDashboardPage() {
  const { axios } = useAxios();
  const { user } = useContext(AppContext);

  const [dashboard, setDashboard] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (user) {
      fecthDashboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const fecthDashboard = async () => {
    try {
      setIsLoading(true);
      const result = await axios.get(`api/v2/student/dashboard`);
      setDashboard({ ...result.data });
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const lastSATTestReport = dashboard?.satHistory?.[dashboard?.satHistory?.length - 1]?.report;
  const ribbons = [
    {
      title: 'SAT Score',
      value: dashboard?.student?.satTestsTakenCount || 0,
      valueComponent: (
        <>
          {lastSATTestReport?.estimatedSATScoreByExpectedTime || 0}
          <Typography component={'span'} variant="subtitle1" sx={{ fontWeight: 400, color: 'text.secondary' }}>
            / {lastSATTestReport?.estimatedSATScore || 0}
          </Typography>
        </>
      ),
      tooltip:
        'Estimated SAT Score from last Diagnostic Test based on expected completion time / SAT Score based on time spent in last test',
      color: 'warning',
    },

    {
      title: 'SAT Time',
      value: dashboard?.student?.practiceTestsTakenCount || 0,
      valueComponent: (
        <>
          {formatDuration(lastSATTestReport?.totalTime || 0, 'no-seconds')}
          <Typography component={'span'} variant="subtitle1" sx={{ fontWeight: 400, color: 'text.secondary' }}>
            {' / '}
            {formatDuration(lastSATTestReport?.expectedTime, 'no-seconds')}
          </Typography>
        </>
      ),
      tooltip: 'Total time spent on the last Diagnostic SAT test / Expected time to complete the test',
      color: 'primary',
    },
    {
      title: `Today's Problems Solved`,
      value: dashboard?.student?.questionsSolvedToday || 0,
      tooltip: 'Correct answered questions today. Solve at least 20 questions daily to improve your score',
      color: 'error',
    },
    {
      title: 'SAT Tests Taken',
      value: dashboard?.student?.satTestsTakenCount || 0,
      tooltip: 'Total number of Diagnostic SAT tests taken',
      color: 'warning',
    },
    {
      title: 'Subject Tests Taken',
      value: dashboard?.student?.practiceTestsTakenCount || 0,
      tooltip: 'Total number of subject practice taken',
      color: 'primary',
    },
    {
      title: 'Total Problems Solved',
      value: (dashboard?.student?.satQuestionsSolved || 0) + (dashboard?.student?.practiceQuestionsSolved || 0),
      tooltip: 'Total amount of correct answered questions in all SAT and Subject practice tests combined',
      color: 'success',
    },
  ];
  return (
    <>
      <Helmet>
        <title> Statistics | SAT Decoded App </title>
      </Helmet>

      <Container maxWidth="xl" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ my: 4, gap: 8 }}>
          <Stack direction="row" alignItems="center">
            <Typography variant="caption" color="text.secondary">
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
                Statistics
              </Typography>
            </Typography>
          </Stack>
        </Stack>
        <Stack
          sx={{
            position: 'relative',
            flex: 1,
          }}
        >
          <CardContent sx={{ height: '100%', p: 0 }}>
            {isLoading && (
              <Box
                sx={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}
                height={'100%'}
                width="100%"
              >
                <CircularProgress />
              </Box>
            )}
            {!isLoading && (
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  height: '100%',
                }}
              >
                <Grid container spacing={3}>
                  {ribbons.map((item, index) => (
                    <Grid item xs={12} sm={4} md={2} key={index}>
                      <Ribbon
                        growInTimeout={300 * (index + 1)}
                        title={item.title}
                        value={item.value}
                        valueComponent={item.valueComponent}
                        tooltip={item.tooltip}
                      />
                    </Grid>
                  ))}

                  <Grid item xs={12} sm={8}>
                    <SATTestHistoryChart
                      data={dashboard?.satHistory?.map((x) => ({ ...x.report, time: x.createdAt })) || []}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SubjectsChart
                      data={dashboard?.student?.subjects || []}
                      hideDetailsButton={
                        !dashboard?.student?.satTestsTakenCount && !dashboard?.student?.practiceTestsTakenCount
                      }
                    />
                  </Grid>
                  <Grid xs={12} md={8} item>
                    <PracticeHistoryTable fetchUrl={'/api/v2/student/practice/history'} variant="student" />
                  </Grid>
                  <Grid xs={12} md={4} item>
                    <TimeSpentToday
                      value={dashboard?.student?.timeSpentToday || 0}
                      maxValueInMinutes={dashboard?.student?.dailyPracticeMinutesTarget || 30}
                    />
                  </Grid>
                </Grid>
              </Stack>
            )}
          </CardContent>
        </Stack>
      </Container>
    </>
  );
}
