export const defaulSubjectIconName = 'WORD PROBLEM LINEAR EQUATION';
export const subjectNameToIconNameMap = {
  'SOLVING LINEAR EQUATIONS & INEQUALITIES': 'SOLVING LINEAR EQUATIONS & INEQUALITIES',
  'WORD PROBLEM: LINEAR EQUATION': 'WORD PROBLEM LINEAR EQUATION',
  'GRAPHS OF LINEAR EQUATIONS': 'GRAPHS OF LINEAR EQUATIONS',
  'SOLVING SYSTEMS OF LINEAR EQUATION': 'SOLVING SYSTEMS OF LINEAR EQUATION',
  'WORD PROBLEMS: SYSTEMS OF LINEAR EQUATIONS': 'WORD PROBLEM LINEAR EQUATION',
  'WORD PROBLEMS: LINEAR INEQUALITY': 'WORD PROBLEMS LINEAR INEQUALITY',
  'GRAPHS OF TWO LINEAR EQUATIONS': 'GRAPHS OF TWO LINEAR EQUATIONS',
  RATIO: 'RATIO',
  RATE: 'RATE',
  PROPORTIONS: 'PROPORTIONS',
  'UNIT CONVERSION': 'UNIT CONVERSION',
  PERCENTAGE: 'PERCENTAGE',
  'DISTRIBUTION PROPERTIES': 'DISTRIBUTION PROPERTIES',
  'DATA REPRESENTATIONS': 'DATA REPRESENTATIONS',
  SCATTERPLOTS: 'SCATTERPLOTS',
  'LINEAR & EXPONENTIAL GROWTH FUNCTIONS': 'LINEAR & EXPONENTIAL GROWTH FUNCTIONS',
  'PROBABILITY & RELATIVE FREQUENCY': 'PROBABILITY & RELATIVE FREQUENCY',
  'DATA INFERENCES': 'DATA INFERENCES',
  'EVALUATING STATISTICAL CLAIMS': 'EVALUATING STATISTICAL CLAIMS',
  'FACTORING QUADRATIC AND POLYNOMIAL EXPRESSION': 'FACTORING QUADRATIC AND POLYNOMIAL EXPRESSION',
  'RADICALS AND RATIONAL EXPONENTS': 'RADICALS AND RATIONAL EXPONENTS',
  DISTRIBUTING: 'DISTRIBUTING',
  'OPERATIONS WITH RATIONAL EXPRESSIONS': 'OPERATIONS WITH RATIONAL EXPRESSIONS',
  'NONLINEAR FUNCTIONS': 'NONLINEAR FUNCTIONS',
  'ISOLATING QUANTITIES': 'ISOLATING QUANTITIES',
  'SOLVING QUADRATIC EQUATIONS': 'SOLVING QUADRATIC EQUATIONS',
  'LINEAR & QUADRATIC SYSTEMS': 'LINEAR & QUADRATIC SYSTEMS',
  'RADICAL, RATIONAL, AND ABSOLUTE VALUE': 'RADICAL, RATIONAL, AND ABSOLUTE VALUE',
  'WORD PROBLEMS: QUADRATIC & EXPONENTIAL': 'WORD PROBLEMS QUADRATIC & EXPONENTIAL',
  'GRAPHS: QUADRATIC': 'GRAPHS QUADRATIC',
  'GRAPHS: EXPONENTIAL': 'WORD PROBLEMS QUADRATIC & EXPONENTIAL',
  'GRAPHS: POLYNOMIAL': 'FACTORING QUADRATIC AND POLYNOMIAL EXPRESSION',
  'AREA & VOLUME': 'PROPORTIONS',
  'ANGLE RELATIONSHIPS': 'DISTRIBUTING',

  'RIGHT TRIANGLE TRIGONOMETRY': 'EVALUATING STATISTICAL CLAIMS',
  'CIRCLE THEOREMS': 'RATIO',
  'UNIT CIRCLE TRIGNONOMETRY': 'RATIO',
  'CIRCLE EQUATIONS': 'OPERATIONS WITH RATIONAL EXPRESSIONS',
  Trigonometry: 'EVALUATING STATISTICAL CLAIMS',
  'Complex Numbers': 'SOLVING SYSTEMS OF LINEAR EQUATION',

  ANGLES: 'DISTRIBUTING',
  PERCENTS: 'PERCENTAGE',
  'WORD PROBLEMS': 'WORD PROBLEM LINEAR EQUATION',
  EXPONENTS: 'RADICALS AND RATIONAL EXPONENTS',
  RATES: 'RATE',
  'ALGEBRAIC EXPRESSIONS': 'OPERATIONS WITH RATIONAL EXPRESSIONS',
};
