import { Suspense, useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import ReactGA from 'react-ga4';

// @mui
import { styled } from '@mui/material/styles';
//
import { AppContext } from '../../App';
// import { Roles } from '../../constants/roles';
import Header from '../../sections/common/header';
import Nav from './Nav';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 64;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  // paddingTop: APP_BAR_MOBILE + 24,
  paddingTop: 24,
  paddingBottom: theme.spacing(14),
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    // paddingTop: APP_BAR_DESKTOP + 24,
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function TeacherPortalLayout() {
  const [open, setOpen] = useState(false);
  const { user: account } = useContext(AppContext);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/teacher', title: 'Teacher Portal' });
  }, []);

  return (
    <StyledRoot>
      <Header role={account.role} onOpenNav={() => setOpen(true)} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} />

      <Main>
        <Suspense>
          <Outlet />
        </Suspense>
      </Main>
    </StyledRoot>
  );
}
