import { lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import Layout from '../layouts/TeacherPortal';

const StudentsListPage = lazy(() => import('../pages/TeacherPortal/v2/Students/ListPage'));
const SATStudentsEditPage = lazy(() => import('../pages/TeacherPortal/v2/Students/EditPage'));
const SATStudentsProfilePage = lazy(() => import('../pages/TeacherPortal/v2/Students/ProfilePage'));

const SATTestsListPage = lazy(() => import('../pages/TeacherPortal/v2/SATTests/ListPage'));
const SATTestsEditPage = lazy(() => import('../pages/TeacherPortal/v2/SATTests/EditPage'));

const PracticeViewPage = lazy(() => import('../pages/TeacherPortal/v2/Students/PracticeViewPage'));
const PracticeListPage = lazy(() => import('../pages/TeacherPortal/v2/PracticeTests/ListPage'));
const PracticeEditTestPage = lazy(() => import('../pages/TeacherPortal/v2/PracticeTests/EditPage'));

const QuestionsPage = lazy(() => import('../pages/TeacherPortal/v2/Questions/ListPage'));
const QuestionEditPage = lazy(() => import('../pages/TeacherPortal/v2/Questions/EditPage'));

const SettingsTestPage = lazy(() => import('../pages/TeacherPortal/v2/Settings'));

// admin
const UsersListPage = lazy(() => import('../pages/Admin/Users/ListPage'));
const UsersEditPage = lazy(() => import('../pages/Admin/Users/EditPage'));
const UserActivities = lazy(() => import('../pages/TeacherPortal/v2/UserActivities'));

export default function Routes(user) {
  return [
    {
      path: '/',
      element: <Layout />,
      children: [
        { element: <Navigate to={'/teacher/students'} />, index: true },

        // V2
        { path: 'teacher/students', element: <StudentsListPage /> },
        { path: 'teacher/students/:id', element: <SATStudentsEditPage /> },
        {
          path: 'teacher/students/:id',
          element: <Outlet />,
          children: [
            { path: 'profile', element: <SATStudentsProfilePage />, index: true },
            { path: 'profile/practice/:practiceId/view', element: <PracticeViewPage /> },
          ],
        },

        { path: 'teacher/sat-tests', element: <SATTestsListPage /> },
        { path: 'teacher/sat-tests/:id', element: <SATTestsEditPage /> },

        { path: 'teacher/practice', element: <PracticeListPage /> },
        { path: 'teacher/practice/:id', element: <PracticeEditTestPage /> },

        {
          path: 'teacher/questions',
          element: <QuestionsPage />,
        },
        { path: 'teacher/questions/:id', element: <QuestionEditPage /> },

        { path: 'teacher/settings', element: <SettingsTestPage /> },

        // ADMIN
        {
          path: 'users',
          element: user?.role === 'admin' ? <UsersListPage /> : <Navigate to="/403" />,
        },
        {
          path: 'users/:id',
          element: user?.role === 'admin' ? <UsersEditPage /> : <Navigate to="/403" />,
        },
        {
          path: 'users-activities',
          element: user?.role === 'admin' ? <UserActivities /> : <Navigate to="/403" />,
        },
      ],
    },
  ];
}
