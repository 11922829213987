import PropTypes from 'prop-types';
import { Box, Card, Grow, Stack, Tooltip, Typography } from '@mui/material';

import Iconify from '../../../components/iconify';

export default function Ribbon({
  icon,
  iconColor,
  title,
  value,
  valueComponent,
  differencePercentage,
  historyChart,
  color,
  tooltip,
  sx,
  growInTimeout,
}) {
  return (
    <Grow in timeout={growInTimeout}>
      <Card
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
          ...(color && { borderLeft: (theme) => `10px solid ${theme.palette[color].dark}` }),
          ...sx,
          // backgroundColor: (theme) => theme.palette.mode === 'light' && 'transparent',
          // boxShadow: 'none',
          '&:hover #help-icon': {
            display: 'block',
            '&:hover': {
              backgroundColor: 'grey.300',
            },
          },
        }}
      >
        {tooltip && (
          <Tooltip title={tooltip} placement="top">
            <Iconify
              icon="eva:question-mark-circle-outline"
              color="info.main"
              roundIconBackground="grey.200"
              width={22}
              variant="round"
              roundIconPadding={0.3}
              id={'help-icon'}
              sx={{ display: 'none', position: 'absolute', top: 8, right: 8, cursor: 'pointer' }}
            />
          </Tooltip>
        )}
        <Box sx={{ width: '100%' }}>
          <Typography variant="h4" component={'div'} gutterBottom>
            {valueComponent || value}
          </Typography>

          <Typography variant="body2" gutterBottom display={'flex'} alignItems="center" color={'text.secondary'}>
            {title}
          </Typography>
          {differencePercentage !== undefined && (
            <Stack display={'flex'} direction={'row'} mt={4}>
              <Iconify
                icon={`eva:trending-${differencePercentage >= 0 ? 'up' : 'down'}-outline`}
                width={24}
                color={`${differencePercentage >= 0 ? 'success' : 'error'}.main`}
                variant="round"
                roundIconPadding={0.5}
                sx={{
                  mr: 1,
                }}
              />
              <Box>
                <Typography variant="subtitle2" component={'div'}>
                  {Math.round(Math.abs(differencePercentage || 0))} %
                  <Box component={'span'} sx={{ fontWeight: 400, color: 'text.secondary' }}>
                    {' '}
                    then previous test
                  </Box>
                </Typography>
              </Box>
            </Stack>
          )}
        </Box>
        {icon && (
          <Stack display={'flex'}>
            <Iconify icon={icon} width={55} color={iconColor || 'primary.main'} variant="round" />
          </Stack>
        )}
        {historyChart && <Box>{historyChart}</Box>}
      </Card>
    </Grow>
  );
}

Ribbon.propTypes = {
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  sx: PropTypes.object,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueComponent: PropTypes.node,
  differencePercentage: PropTypes.number,
  historyChart: PropTypes.node,
  color: PropTypes.string,
  tooltip: PropTypes.string,
  growInTimeout: PropTypes.number,
};
