import { createContext, useEffect, useState } from 'react';
import { SnackbarProvider } from 'notistack';
import ReactGA from 'react-ga4';
// import * as amplitude from '@amplitude/analytics-browser';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
import { collectCookies, useAuth } from './services/auth';

const TRACKING_ID = 'G-H6304PBPGV'; // your Measurement ID

// ----------------------------------------------------------------------
export const AppContext = createContext({});

export default function App() {
  const auth = useAuth();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize(TRACKING_ID);
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = async () => {
    collectCookies();
    const user = await auth.authorise();
    if (user) {
      setUser(user);
      // if (user.role === 'student' && process.env.NODE_ENV === 'production' && user.recordAmplitudeSession) {
      //   // record session
      //   amplitude.init('9d329efa01d3d37cc3553d96a99c9ebf', {
      //     serverZone: 'EU',
      //     userId: user.email,
      //     autocapture: {
      //       elementInteractions: true,
      //     },
      //   });
      // } else {
      //   console.log('not recording session', {
      //     role: user.role,
      //     env: process.env.NODE_ENV,
      //     recordAmplitudeSession: user.recordAmplitudeSession,
      //   });
      // }
    }
    setLoading(false);
  };

  if (loading) {
    return null;
  }

  return (
    <SnackbarProvider maxSnack={3}>
      <AppContext.Provider
        value={{
          user,
          setUser,
        }}
      >
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <Router user={user} />
        </ThemeProvider>
      </AppContext.Provider>
    </SnackbarProvider>
  );
}
