import { Box, Button, CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const Img = ({ src, alt, reloadEnabled = false }) => {
  const [isLoading, setIsLoading] = useState(false); // Track loading state
  const [loadError, setLoadError] = useState(false); // Track error state
  const [reloadSrc, setReloadSrc] = useState(src); // Track reload-able src

  const handleImageLoad = () => {
    setIsLoading(false);
    setLoadError(false);
  };
  const handleImageError = () => setLoadError(true); // Show error state on fail

  const reloadImage = () => {
    setLoadError(false); // Reset error state
    setIsLoading(true); // Reset loading state
    setReloadSrc(`${src}?reload=${new Date().getTime()}`); // Force reload with cache-busting
  };

  useEffect(() => {
    reloadImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  // Timeout to hide image if it doesn't load within 10 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      if (isLoading) {
        setLoadError(true);
        setIsLoading(false);
      }
    }, 10 * 1000); // 10 seconds

    return () => clearTimeout(timer); // Cleanup on unmount or when image loads
  }, [isLoading]);

  return (
    <Box id="Img" key={src}>
      {isLoading && !loadError && <CircularProgress />} {/* Show loader */}
      {loadError && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Typography
            variant="body2"
            fontSize={12}
            color={'text.secondary'}
            sx={{
              fontSize: '14px !important',
            }}
          >
            Failed to load image.
          </Typography>
          {reloadEnabled && (
            <Button size="small" onClick={reloadImage} variant="outlined">
              Reload
            </Button>
          )}
        </Box>
      )}
      <img
        src={reloadSrc}
        alt={alt}
        onLoad={handleImageLoad} // Triggered on successful load
        onError={handleImageError} // Triggered on load failure
        style={{ display: isLoading || loadError ? 'none' : 'block' }}
      />
    </Box>
  );
};

export default Img;

Img.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  reloadEnabled: PropTypes.bool,
};
