import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as EmailValidator from 'email-validator';
import ReactGA from 'react-ga4';

import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import Iconify from '../../components/iconify';
import CookiesPopup from '../../components/cookies-popup';
import { bgGradient } from '../../utils/cssStyles';
import Footer from './Footer';

import { useAxios } from '../../services/axiosService';

const cards = [
  {
    title: 'Detail Diagnostics',
    description:
      'After each exam, you will get a <u>detailed topic breakdown</u> of you <strong>strengths and weakness</strong>.',
    imageUrl: '/assets/images/landing/hero3.png',
  },
  {
    title: 'Advance Progess Tracking',
    description:
      '<u>As you progress</u>, you will see how long it takes for you to solve a particular type of problem.',
    imageUrl: '/assets/images/landing/advanced-progress-tracking3.png',
  },
  {
    title: 'Topic Specific Practice',
    description: 'You will get <u>practice problems</u> that targets those questions where you are the weaknest at.',
    imageUrl: '/assets/images/landing/topic-specific-practice2.png',
  },
  {
    title: 'Active Recall',
    description:
      'Active recall is an effective learning method because it <strong>strengthens memory</strong> retention and enhances understanding by <u>forcing the brain to actively engage</u> with the material, rather than passively reviewing it.',
    // imageUrl: '/assets/images/landing/subject-efficiency.png',
  },
  {
    title: 'Spaced Repetitions',
    description:
      'Spaced repetition is a <strong>powerful learning technique</strong> that leverages the spacing effect to improve long-term retention by strategically scheduling reviews of material at increasing intervals.',
    // imageUrl: '/assets/images/landing/hero2.png',
  },
  {
    title: 'Problem Stacking',
    description:
      'Problem stacking gradually builds your skills and confidence by tackling problems that progressively increase in difficulty, ensuring a solid foundation before moving on to more challenging material.',
    // imageUrl: '/assets/images/landing/hero2.png',
  },
];

export default function HormoziStyle() {
  const theme = useTheme();
  const navigate = useNavigate();

  const { axios } = useAxios();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [name, setName] = useState('');

  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError('');
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const onSubmit = async () => {
    setLoading(true);
    if (!email) {
      setEmailError('Email is required');
      return;
    }
    if (!EmailValidator.validate(email)) {
      setEmailError('Email is not valid');
      return;
    }
    setEmailError('');

    try {
      ReactGA.event({ eventCategory: 'CTA', eventAction: 'click', eventLabel: 'Sign Up Email' });
      await axios.post(`api/v2/public/sign-up-request`, { email, name });
      setSuccess(true);
    } catch (error) {
      console.error(error);
      if (error.response?.data?.message) {
        setEmailError(error.response.data.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
  }, []);

  return (
    <>
      <Helmet>
        <title> Home | SAT Decoded App </title>
      </Helmet>

      <Container maxWidth="md" sx={{ mt: 3 }}>
        <Stack display="flex" flexDirection={'row'} sx={{ mb: 3 }}>
          <Box
            component="img"
            src="/assets/logo-text.png"
            sx={{ height: 40, cursor: 'pointer' }}
            alt="SAT Decoded App"
          />
          <Box flexGrow={1} />
          <Button
            variant="contained"
            onClick={() => {
              ReactGA.event({ eventAction: 'click', eventLabel: 'Login' });
              navigate('/login');
            }}
          >
            Login
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              ReactGA.event({ eventAction: 'click', eventLabel: 'Contact' });
              navigate('/contact');
            }}
            sx={{ ml: 2 }}
          >
            Contact Us
          </Button>
        </Stack>
        <Box>
          <Typography variant="h3" align="center" component={'h1'}>
            The SAT App We Use To Get Students To <br /> Increase Their SAT Math Score By 100+ Points <br /> In 3
            Months! Guaranteed!
          </Typography>
          <Typography variant="body1" align="center" component={'h2'}>
            Discover the{' '}
            <Typography component="span" variant="subtitle1" sx={{ textDecoration: 'underline' }}>
              BEST
            </Typography>{' '}
            SAT Prep app for acing the SAT FAST <br /> even if you struggle with test anxiety :)
          </Typography>
        </Box>
        <Stack sx={{ display: 'flex', flexDirection: { sm: 'column', md: 'row' }, gap: 4, mt: 2 }}>
          <Box
            sx={{
              ...bgGradient({
                startColor: theme.palette.grey[400],
                endColor: theme.palette.grey[500],
              }),
              p: { xs: 2 },
              borderRadius: { xs: 'calc(12px + 16px)' },
              height: 'fit-content',
            }}
          >
            <img src="/assets/images/landing/hero3.png" alt="SAT Decoded App" style={{ borderRadius: 12 }} />
          </Box>
          <Box sx={{ minWidth: 300 }}>
            <Card>
              {!success && (
                <CardContent sx={{}}>
                  <Box sx={{ backgroundColor: '', borderRadius: 1, p: 1 }}>
                    <Typography variant="body2" align="center" fontWeight={500}>
                      Enter Your Email Address And We’ll Send You Credentials To Login Into{' '}
                      <Typography
                        variant="body2"
                        component={'span'}
                        sx={{ textDecoration: 'underline' }}
                        fontWeight={500}
                      >
                        SAT Decoded App
                      </Typography>
                    </Typography>
                  </Box>
                  <Box my={2}>
                    <TextField
                      fullWidth
                      label="Email*"
                      sx={{ mb: 2 }}
                      size="small"
                      value={email}
                      onChange={handleEmailChange}
                      error={Boolean(emailError)}
                      helperText={emailError}
                    >
                      Email
                    </TextField>
                    <TextField
                      fullWidth
                      label="Name"
                      sx={{ mb: 2 }}
                      size="small"
                      value={name}
                      onChange={handleNameChange}
                    >
                      Name
                    </TextField>
                    <Button variant="contained" color="warning" fullWidth onClick={onSubmit} disabled={loading}>
                      {loading ? 'Sending email...' : 'Get Started'}
                    </Button>
                  </Box>

                  <Divider sx={{ borderStyle: 'dashed', mt: 2 }} />
                  <Typography variant="caption" component={'p'} align="center" mt={2}>
                    P.S. Leave Your Credit Card At Home...
                  </Typography>
                  <Typography variant="caption" align="center" component={'p'} mt={2} fontSize={11}>
                    YOUR INFORMATION IS 100% SAFE. WE NEVER SHARE YOUR INFORMATION WITH ANYONE. BY CLICKING 'SUBMIT' I
                    AGREE TO RECEIVE AUTO E-MAILS. I UNDERSTAND THAT MY CONSENT IS NOT A CONDITION TO PURCHASE ANYTHING.
                  </Typography>
                </CardContent>
              )}
              {success && (
                <CardContent>
                  <Iconify icon="eva:checkmark-circle-2-outline" color="success.main" sx={{ mb: 2 }} width={30} />
                  <Typography variant="h5" align="left" component={'h2'}>
                    Welcome To SAT Decoded
                  </Typography>
                  <Typography variant="body2" align="left" component={'p'}>
                    We have sent you an email with your login credentials at <strong>{email}</strong>. If you do not see
                    email, please check spam folder.
                  </Typography>
                </CardContent>
              )}
            </Card>
          </Box>
        </Stack>
      </Container>
      <Container maxWidth="100%" sx={{ mt: 3, backgroundColor: 'grey.800', p: 4 }}>
        <Typography variant="h4" align="center" component={'h2'} color="white">
          These Are The{' '}
          <Typography variant="h4" component={'span'}>
            EXACT
          </Typography>{' '}
          Strategies <br /> We’ve used to Help Students Achieve <br /> 100+ Points Increase In Just 3 Months!
        </Typography>
        <Typography align="center" color="error.light">
          (Here's the preview of what you get today for FREE !!)
        </Typography>
      </Container>
      <Container
        maxWidth="md"
        sx={{
          mt: 3,
          p: 2,
          display: 'grid',
          gap: 2,
          gridTemplateColumns: { sm: 'repeat(1, 1fr)', md: 'repeat(3, 2fr)' },
        }}
      >
        {cards.map((item, index) => (
          <Card key={index}>
            <CardContent>
              {item.imageUrl && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                  <img src={item.imageUrl} alt={item.title} style={{ width: '100%', borderRadius: 12 }} />
                </Box>
              )}
              <Typography variant="h5" align="center" component={'h3'} gutterBottom>
                {item.title}
              </Typography>
              <Typography
                variant="body1"
                align="center"
                component={'p'}
                dangerouslySetInnerHTML={{ __html: item.description }}
              ></Typography>
            </CardContent>
          </Card>
        ))}
        <Button
          variant="contained"
          color="error"
          sx={{ gridColumn: '1 / -1' }}
          size="large"
          onClick={() => {
            ReactGA.event({ eventCategory: 'CTA', eventAction: 'click', eventLabel: 'CTA-1' });
            window.scrollTo(0, 0);
          }}
        >
          <Box>
            YES! I Want Access to 'SAT Decoded App'
            <br />
            <Typography variant="caption" align="center" component={'p'}>
              Give me Instant access To The SAT Decoded App Now!
            </Typography>
          </Box>
        </Button>
      </Container>
      <Container maxWidth="100%" sx={{ mt: 3, backgroundColor: 'grey.800', p: 4 }}>
        <Typography variant="h3" align="center" component={'h2'} color="white">
          Here’s Everything You’re Getting <br /> When You Sign In Today…
        </Typography>
        <Typography align="center" color="white" variant="body2">
          We Can't Wait For You To Experience First Hand How Much More Powerful And <br /> Complete These Systems Are{' '}
          <Typography component={'span'} sx={{ fontWeight: 600 }}>
            Than Anything YOU’ve Tried Before
          </Typography>
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 4,
          }}
        >
          <img src="/assets/images/landing/hero3.png" alt="SAT Decoded App" style={{ borderRadius: 12, width: 500 }} />
        </Box>

        <Typography align="center" color="white" variant="h3" mt={4}>
          100% FREE ACESS OFFER!
        </Typography>

        <Stack color={'white'} display={'flex'} alignItems={'center'} sx={{ mt: 1 }}>
          <Stack>
            <Box sx={{ flexDirection: 'row', display: 'flex', my: 0.5 }}>
              <Iconify icon="eva:checkmark-circle-2-outline" color="success.main" sx={{ mr: 2 }} />
              <Typography variant="h6">
                1 year app access{' '}
                <Typography component="span" sx={{ color: 'error.light' }}>
                  (Full Access)
                </Typography>
              </Typography>
            </Box>
            <Box sx={{ flexDirection: 'row', display: 'flex', my: 0.5 }}>
              <Iconify icon="eva:checkmark-circle-2-outline" color="success.main" sx={{ mr: 2 }} />
              <Typography variant="h6">
                2000+ Practice Questions{' '}
                <Typography component="span" sx={{ color: 'error.light' }}>
                  ($50 value)
                </Typography>
              </Typography>
            </Box>
            <Box sx={{ flexDirection: 'row', display: 'flex', my: 0.5 }}>
              <Iconify icon="eva:checkmark-circle-2-outline" color="success.main" sx={{ mr: 2 }} />
              <Typography variant="h6">
                Bonus: 40+ Subjects Specific Tests{' '}
                <Typography component="span" sx={{ color: 'error.light' }}>
                  ($30 value)
                </Typography>
              </Typography>
            </Box>
            <Box sx={{ flexDirection: 'row', display: 'flex', my: 0.5 }}>
              <Iconify icon="eva:checkmark-circle-2-outline" color="success.main" sx={{ mr: 2 }} />
              <Typography variant="h6">
                Bonus: 10 Diagnostic SAT Tests{' '}
                <Typography component="span" sx={{ color: 'error.light' }}>
                  ($50 value)
                </Typography>
              </Typography>
            </Box>

            <Box sx={{ flexDirection: 'row', display: 'flex', my: 0.5 }}>
              <Iconify icon="eva:checkmark-circle-2-outline" color="success.main" sx={{ mr: 2 }} />
              <Typography variant="h6">
                Bonus: Video Lessons{' '}
                <Typography component="span" sx={{ color: 'error.light' }}>
                  ($100 value)
                </Typography>
              </Typography>
            </Box>
            <Box sx={{ flexDirection: 'row', display: 'flex', my: 0.5 }}>
              <Iconify icon="eva:checkmark-circle-2-outline" color="success.main" sx={{ mr: 2 }} />
              <Typography variant="h6">
                Bonus: Memory Notecard System (Anki-like)
                <Typography component="span" sx={{ color: 'error.light' }}>
                  ($300 value)
                </Typography>
              </Typography>
            </Box>
          </Stack>

          <Typography variant="h5" align="center" component={'h2'} color="error.light" mt={2}>
            Total Value:{' '}
            <Typography
              variant="h5"
              align="center"
              component={'span'}
              color="error.ligth"
              sx={{ textDecoration: 'line-through' }}
            >
              $530.00
            </Typography>
          </Typography>
          <Typography variant="h5" align="center" component={'h2'} color="white">
            Get It All Now
          </Typography>
          <Typography variant="h3" align="center" component={'h2'} color="warning.main">
            For FREE
          </Typography>

          <Button
            variant="contained"
            color="error"
            sx={{ gridColumn: '1 / -1', mt: 2, px: { xs: 0, sm: 0, md: 20 } }}
            size="large"
            onClick={() => {
              ReactGA.event({ eventCategory: 'CTA', eventAction: 'click', eventLabel: 'CTA-2' });
              window.scrollTo(0, 0);
            }}
          >
            <Box>
              YES! I Want Access to 'SAT Decoded App'
              <br />
              <Typography variant="caption" align="center" component={'p'}>
                Give me Instant access To The SAT Decoded App Now!
              </Typography>
            </Box>
          </Button>
        </Stack>
      </Container>

      <Footer />
      <CookiesPopup />
    </>
  );
}
