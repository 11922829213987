import PropTypes from 'prop-types';
import _ from 'lodash';
import Label from '../../../components/label';

export default function RoleLabel({ role }) {
  return (
    <Label color={role === 'admin' ? 'error' : role === 'teacher' ? 'warning' : 'info'} variant="soft">
      {_.upperCase(role)}
    </Label>
  );
}

RoleLabel.propTypes = {
  role: PropTypes.string,
};
