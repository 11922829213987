import PropTypes from 'prop-types';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';

import Iconify from '../iconify';

import { useTableContext } from './TableContext';

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  minHeight: 96,
  display: 'flex',
  padding: theme.spacing(2, 1, 2, 3),
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

export default function TableToolbar({ title, filtersConfig, onFilterChange }) {
  const { filters, setFilters, filterEnabled } = useTableContext();

  if (!title && !filtersConfig?.length) {
    return null;
  }
  return (
    <StyledRoot>
      {title && <Typography variant="h6">{title}</Typography>}
      {filtersConfig?.map((filter) => {
        if (filter.type === 'search') {
          return (
            <OutlinedInput
              key={filter.name}
              variant="outlined"
              placeholder={`Search by ${filter.label}...`}
              value={filters[filter.name] || ''}
              onChange={(e) => onFilterChange(filter.name, e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon={'eva:search-fill'} sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }} />
                </InputAdornment>
              }
            />
          );
        }
        if (filter.type === 'select') {
          const { multiple } = filter;
          const value = filters[filter.name] || (multiple ? [] : '');
          return (
            <FormControl sx={{ width: 300, mr: 1 }} key={filter.name}>
              <InputLabel id={filter.label}>{filter.label}</InputLabel>
              <Select
                onChange={(e) => onFilterChange(filter.name, e.target.value)}
                input={<OutlinedInput label={filter.label} />}
                multiple={multiple}
                value={value}
                MenuProps={MenuProps}
                {...(multiple && {
                  renderValue: (selected) => selected.join(', '),
                })}
              >
                {filter.options.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    sx={{
                      m: 1,
                      p: 1,
                      pl: multiple ? 0 : 1,
                      fontWeight: filters[filter.name]?.indexOf(option.value) > -1 ? 'bold' : 'normal',
                      borderRadius: '6px',
                    }}
                  >
                    {multiple && <Checkbox checked={filters[filter.name]?.indexOf(option.value) > -1} />}
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        }
        if (filter.type === 'autocomplete') {
          const { multiple } = filter;
          let value;
          if (multiple) {
            value = filter.options.filter((option) => filters[filter.name]?.indexOf(option.value) > -1) || [];
          } else {
            value = filter.options.find((option) => filters[filter.name] === option.value) || undefined;
          }

          return (
            <FormControl sx={{ minWidth: 350, mr: 1 }} key={filter.name}>
              <Autocomplete
                multiple={multiple}
                options={filter.options}
                getOptionLabel={(option) => option.label}
                value={value}
                onChange={(e, value) =>
                  onFilterChange(filter.name, multiple ? value?.map((v) => v.value) : value?.value)
                }
                renderInput={(params) => <TextField {...params} label={filter.label} />}
                renderOption={(props, option) => (
                  <MenuItem
                    {...props}
                    key={option.value}
                    sx={{
                      margin: 1,
                      fontWeight: filters[filter.name]?.indexOf(option.value) > -1 ? 'bold' : 'normal',
                      borderRadius: 1,
                      height: 55,
                    }}
                  >
                    {multiple && <Checkbox checked={filters[filter.name]?.indexOf(option.value) > -1} />}
                    {option.label}
                  </MenuItem>
                )}
                limitTags={1}
              />
            </FormControl>
          );
        }
        return null;
      })}
      <Box flexGrow={1} />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', ml: 2 }}>
        {filterEnabled && (
          <Tooltip title="Clear all filters">
            <IconButton onClick={() => setFilters({})} color="error">
              <Iconify icon="eva:trash-2-fill" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </StyledRoot>
  );
}

TableToolbar.propTypes = {
  title: PropTypes.string,
  filtersConfig: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.oneOf(['search', 'select', 'autocomplete']),
      multiple: PropTypes.bool,
      options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),
    })
  ),
  filters: PropTypes.object,
  onFilterChange: PropTypes.func,
  clearFilters: PropTypes.func,
  filterEnabled: PropTypes.bool,
};
