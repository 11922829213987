export const QuestionTypes = {
  text: 'text',
  math: 'math',
  image: 'image',
  html: 'html',
};

export const QuestionTypeIconMapping = {
  [QuestionTypes.text]: 'eva:file-text-outline',
  [QuestionTypes.image]: 'eva:image-outline',
  [QuestionTypes.math]: 'mingcute:formula-line',
};

export const QuestionTypeTabMapping = {
  [QuestionTypes.text]: 0,
  [QuestionTypes.math]: 1,
  [QuestionTypes.image]: 3,
};

export const TabQuestionTypeMapping = {
  0: QuestionTypes.text,
  1: QuestionTypes.math,
  3: QuestionTypes.image,
};

export const mapOldQuestionTypeToNew = (question) => {
  if (!question) return null;

  const newQuestion = { ...question };

  if (!newQuestion?.content?.type) {
    if (newQuestion.picture) {
      newQuestion.content = { type: 'image', data: { image: question.picture } };
    } else if (newQuestion.formula) {
      newQuestion.content = { type: 'math', data: { formula: question.formula, text: question.textQuestion } };
    } else {
      newQuestion.content = { type: 'text', data: { text: question.textQuestion } };
    }
  }
  return newQuestion;
};

export const AnswerTypes = {
  default: 'default',
  abcd: 'abcd',
  'abcd(html)': 'abcd(html)',
};

export const SolutionTypes = {
  text: 'text',
  image: 'image',
  html: 'html',
};
