/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import { Stack, styled, Typography } from '@mui/material';

const StyledContainer = styled(Stack)(({ variant }) => {
  const memoMode = variant === 'memo';

  return {
    '& img': {
      maxHeight: 600,
      margin: '15px auto',
    },
    ...(memoMode && {
      '& img': {
        maxHeight: 250,
        margin: '15px auto',
      },
    }),
  };
});

export default function Solution(props) {
  const { solution } = props;

  if (!solution) {
    return null;
  }

  console.log('solution', solution, props);

  return (
    <StyledContainer variant={props.variant || 'default'}>
      {solution?.type === 'image' && solution?.image && (
        <img src={solution?.image} alt={'Could not load the solution. Try to reload the page'} aria-hidden="true" />
      )}
      {solution?.type === 'text' && solution?.text && (
        <Stack sx={{ p: 2, alignItems: 'center', minHeight: 300, justifyContent: 'center' }}>
          <Typography fontSize={24} letterSpacing={1} sx={{ whiteSpace: 'pre-line' }}>
            {solution?.text}
          </Typography>
        </Stack>
      )}
      {solution?.type === 'html' && solution?.data?.html && (
        <div style={{ fontSize: 16 }} dangerouslySetInnerHTML={{ __html: solution?.data?.html }} />
      )}
    </StyledContainer>
  );
}

Solution.propTypes = {
  solution: PropTypes.object,
  variant: PropTypes.string,
};
