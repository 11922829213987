import { useEffect, useState } from 'react';
import _ from 'lodash';
import { faker } from '@faker-js/faker';
import * as Cookies from 'es-cookie';

// @mui
import { styled, alpha, useTheme } from '@mui/material/styles';
import { Box, Link, Drawer, Typography, Avatar, Stack, Button } from '@mui/material';

// components
import Modal from '../../../components/Dialog';

// services
import { useAxios } from '../../../services/axiosService';
import PlanLabel from '../user/PlanLabel';
import RoleLabel from '../user/RoleLabel';

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

const avatarURLs = [...Array(23)].map((_, index) => `/assets/images/avatars/avatar_${index + 1}.jpg`);
avatarURLs.push('/assets/images/avatars/avatar_default.jpg');

export default function Profile(props) {
  const theme = useTheme();
  const { axios } = useAxios();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const toggle = () => {
    setOpen(!open);
  };

  const [selectedAvatar, setSelectedAvatar] = useState();

  useEffect(() => {
    const av = avatarURLs.find((x) => x.includes(props.account.avatar)) || '/assets/images/avatars/avatar_default.jpg';
    setSelectedAvatar(av);
  }, [props.account?.avatar]);

  const onSave = async () => {
    try {
      const res = await axios.post(`/api/student/student-portal/changeAvatar`, { avatar: selectedAvatar });

      if (res.data.token) {
        Cookies.set('token', res.data.token);
        props?.setUser({ ...props.account, avatar: selectedAvatar });
        localStorage.setItem('token', res.data.token);
      }
    } catch (err) {
      console.error(err.message);
    } finally {
      toggle();
    }
  };

  const avatarName = props.account?.name || props.account?.email;
  return (
    <>
      <Box>
        <Link underline="none">
          <StyledAccount>
            <Avatar sx={{ width: 46, height: 46 }}>{avatarName?.charAt(0) || ''}</Avatar>

            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary', mb: 1 }} align="center">
                {props.account?.name || props.account?.email}
              </Typography>

              {props.account?.role === 'student' ? (
                <></>
              ) : (
                // <PlanLabel plan={props.account.plan} />
                <RoleLabel role={props.account.role} />
              )}

              {/* {props.account.role === 'student' && (
                <Link variant="body2" sx={{ cursor: 'pointer' }} onClick={toggle}>
                  Change avatar
                </Link>
              )} */}
            </Box>
          </StyledAccount>
        </Link>
      </Box>
      <Modal open={open} handleOpen={handleOpen} handleClose={handleClose} maxWidth="md">
        <Box p={2}>
          <Stack direction={'row'} justifyContent={'space-between'} m={1} mb={2}>
            <Typography variant="h6">Select you avatar</Typography>
            <Button color="inherit" onClick={toggle}>
              Close
            </Button>
          </Stack>
          <Stack direction={'row'} flexWrap={'wrap'}>
            {avatarURLs.map((item) => (
              <Avatar
                key={item}
                src={item}
                alt={item}
                onClick={() => setSelectedAvatar(item)}
                sx={{
                  m: 1,
                  width: 76,
                  height: 76,
                  border: selectedAvatar === item ? `3px solid ${theme.palette.primary.main}` : '',
                  '&:hover': {
                    cursor: 'pointer',
                    transform: 'scale3d(1.05, 1.05, 1)',
                  },
                }}
              />
            ))}
          </Stack>
          <Stack direction={'row'} justifyContent={'flex-end'} sx={{ mt: 3 }}>
            <Button variant="contained" onClick={onSave}>
              Save changes
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}
