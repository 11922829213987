import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Grow, useTheme } from '@mui/material';
import ReactApexChart from 'react-apexcharts';

import { useChart } from '../../../components/chart';

import { formatDuration } from '../../../utils/formatTime';

export default function TimeSpentToday({ value, maxValueInMinutes, growInTimeout }) {
  const theme = useTheme();

  const options = useChart({
    colors: [theme.palette.violet.main],
    chart: {
      sparkline: {
        enabled: true,
      },
      offsetY: -20,
    },
    legend: {
      show: false,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '65%',
        },
        startAngle: -90,
        endAngle: 90,
        track: {
          strokeWidth: '50%',
          marging: 5,
        },
        dataLabels: {
          name: {
            show: true,
            fontSize: theme.typography.body2.fontSize,
            color: theme.palette.text.secondary,
            fontWeight: 400,
          },
          value: {
            offsetY: -50,
            fontSize: theme.typography.h4.fontSize,
            formatter: () => formatDuration(value, 'no-seconds'),
          },
          total: {
            show: false,
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
        bottom: 25,
        left: 10,
        right: 10,
      },
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        filter: {
          type: 'none',
        },
      },
    },
    labels: [`Daily target is ${maxValueInMinutes} minutes`],
  });

  return (
    <Grow in timeout={growInTimeout || 1000}>
      <Card>
        <CardHeader title={'Time Spent Today'} />

        <CardContent sx={{}}>
          <ReactApexChart
            series={[(value * 100) / (maxValueInMinutes * 60)]}
            options={{ ...options }}
            height={400}
            type="radialBar"
          />
        </CardContent>
      </Card>
    </Grow>
  );
}

TimeSpentToday.propTypes = {
  value: PropTypes.number.isRequired,
  maxValueInMinutes: PropTypes.number.isRequired,
  growInTimeout: PropTypes.number,
};
