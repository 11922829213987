import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import MainLayout from '../layouts/Main';
import LandingPage from '../pages/Main/HomePage';
import LoginPage from '../pages/Main/LoginPage';
import LoginSuccessPage from '../pages/Main/LoginSuccessPage';
import ContactPage from '../pages/Main/ContactPage';
// import RegisterPage from '../pages/Main/RegisterPage';
// import TestPage from '../pages/StudentPortal/TestPage';

const QuestionPage = lazy(() => import('../pages/Main/QuestionPage'));

export default function Routes() {
  return [
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <Navigate to={'/home'} />, index: true },
        {
          path: '/home',
          element: <LandingPage />,
        },
        {
          path: '/contact',
          element: <ContactPage />,
        },
        {
          path: '/login',
          element: <LoginPage />,
        },
        // {
        //   path: '/register',
        //   element: <RegisterPage />,
        // },
        {
          path: '/login-success/:token',
          element: <LoginSuccessPage />,
        },

        // {
        //   path: '/free-test',
        //   element: <TestPage freeTest />,
        // },
        {
          path: '/question/:id',
          element: <QuestionPage />,
        },
      ],
    },
  ];
}
