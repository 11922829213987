/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';

// mui
import { alpha, Box, Button, Card, CardContent, CardHeader, Chip, IconButton, Stack, useTheme } from '@mui/material';

// components
import { Table, TableProvider, useTableContext } from '../../../components/table';
import Modal from '../../../components/Dialog';
import Iconify from '../../../components/iconify';
import Label from '../../../components/label';
import Question from '../../../components/question';
import Solution from '../../../components/question/Solution';

import { formatDuration } from '../../../utils/formatTime';
import { AppContext } from '../../../App';
import { removeNumbers } from '../../../utils/strings';

function QuestionsList({ questions = [], teacher = false, sat = false, tableWrapper, title }) {
  const { user } = useContext(AppContext);

  const theme = useTheme();
  const { loadLocalData } = useTableContext();
  const [popupShow, setPopupShow] = useState(false);
  const [popupRow, setPopupRow] = useState(false);

  useEffect(() => {
    loadLocalData(questions);
  }, [questions]);

  const onQuestionContentClick = (row) => {
    setPopupShow(true);
    setPopupRow({ ...row, type: 'question' });
  };

  const onSolutionClick = (row) => () => {
    setPopupShow(true);
    setPopupRow({ ...row, type: 'solution' });
  };

  const handlePopupClose = () => {
    setPopupShow(false);
    setPopupRow(null);
  };

  const columns = [
    {
      id: 'question',
      label: 'Question',
      disableSort: true,
      onClick: onQuestionContentClick,
      sx: { cursor: 'zoom-in', whiteSpace: 'break-spaces' },
      renderCell: (row, _, idx) => {
        const { topic, subject, level, previousMistake } = row;

        const difficulty = ['L1', 'L2', 'EASY']?.includes(level)
          ? 'easy'
          : ['L3', 'MEDIUM']?.includes(level)
          ? 'medium'
          : 'hard';

        const color =
          difficulty === 'adaptive'
            ? 'violet'
            : difficulty === 'easy'
            ? 'success'
            : difficulty === 'medium'
            ? 'warning'
            : 'error';
        return (
          <Box sx={{ pb: 2 }}>
            {teacher && (
              <Stack sx={{ display: 'flex', flexDirection: 'row', mb: 2, gap: 1 }}>
                <Chip label={`${idx + 1}.`} size="small" />
                {level && (
                  <Label color={color} startIcon={<Iconify icon="eva:bar-chart-outline" />}>
                    {level}
                  </Label>
                )}
                {user.role === 'admin' && topic && <Chip label={topic} size="small" />}
                <Chip label={removeNumbers(subject)} size="small" />
                {previousMistake && (
                  <Label color={'info'} startIcon={<Iconify icon="eva:repeat-outline" color="info" />}>
                    Previous Mistake
                  </Label>
                )}
              </Stack>
            )}
            <Question question={row} variant="cell" key={row.picture || row.content?.data?.image} />
          </Box>
        );
      },
    },
    {
      id: 'correct',
      label: 'Answer',
      renderCell: ({ userAnswer }) => <strong>{userAnswer || 'N/A'}</strong>,
      sx: ({ correct }) => ({ color: correct ? 'green' : 'red' }),
    },
    {
      id: 'answer',
      label: 'Correct Answer',
    },
    {
      id: 'time',
      label: 'Time',
      renderCell: ({ time }) => formatDuration(time, 1),
      sx: ({ time, expectedTime }) => ({ color: time < expectedTime ? 'green' : 'red' }),
    },
    {
      id: 'hasSolution',
      label: 'Solution',
      disableSort: true,
      renderCell: (row) =>
        !row.hasSolution ? (
          <IconButton disabled>
            <Iconify icon="eva:bulb-outline" color="text.secondary" />
          </IconButton>
        ) : (
          <IconButton onClick={onSolutionClick(row)}>
            <Iconify icon="eva:bulb-outline" color="primary.main" />
          </IconButton>
        ),
    },
  ]
    .filter((x) => (!teacher && sat ? x.id !== 'answer' && x.id !== 'hasSolution' : x))
    .filter((x) => (teacher ? x : x.id !== 'answer'));

  const rowActions = [
    {
      label: 'Open in new tab',
      icon: 'eva:diagonal-arrow-right-up-outline',
      onClick: (row) => {
        window.open(`/teacher/questions/${row.questionId}`, '_blank');
      },
      showInCell: true,
    },
  ];

  const rowStyle = (row) => ({
    background: !row.correct ? alpha(theme.palette.error.lighter, 0.2) : alpha(theme.palette.success.lighter, 0.1),
  });

  const TableWrapper = tableWrapper || Card;

  return (
    <div style={{ position: 'relative' }}>
      <TableWrapper>
        <Table
          title={title}
          columns={columns}
          hover
          rowActions={user.role === 'admin' ? rowActions : []}
          hideMenuButton
          hidePagination
          rowStyle={rowStyle}
        />
      </TableWrapper>

      <Stack sx={{ padding: 3 }} direction={'row'} justifyContent={'space-between'}>
        <Modal open={popupShow} handleClose={handlePopupClose}>
          <CardHeader
            title={popupRow?.type === 'question' ? 'Question' : 'Solution'}
            action={
              <Button
                color="inherit"
                onClick={handlePopupClose}
                startIcon={<Iconify icon="eva:close-outline" color="inherit" />}
                variant="contained"
              >
                Close
              </Button>
            }
          />
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {popupRow?.type === 'question' && (
              <>
                <Question question={popupRow} />
              </>
            )}
            {popupRow?.type === 'solution' && (
              <>
                <Solution solution={popupRow.solution} />
              </>
            )}
          </CardContent>
        </Modal>
      </Stack>
    </div>
  );
}

export default function QuestionsListWrapper(props) {
  return (
    <TableProvider>
      <QuestionsList {...props} />
    </TableProvider>
  );
}

QuestionsList.propTypes = {
  questions: PropTypes.array,
  teacher: PropTypes.bool,
  sat: PropTypes.bool,
  tableWrapper: PropTypes.elementType,
  title: PropTypes.string,
};
