/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState, useEffect } from 'react';

import { useAxios } from '../../services/axiosService';
import { applyFilter, getComparator } from './local-data-utils';

const TableContext = createContext({});

export const useTableContext = () => useContext(TableContext);

export const TableProvider = ({ fetchUrl, children, defaultSorting, defaultPagination }) => {
  const { axios } = useAxios();

  const [type] = useState(!fetchUrl ? 'local' : 'remote');

  const [loading, setLoading] = useState(true);
  const [localData, setLocalData] = useState([]); // for type === 'local'
  const [data, setData] = useState({
    docs: [],
    totalDocs: 0,
  });
  const [pagination, setPagination] = useState({
    page: defaultPagination?.page || 0,
    rowsPerPage: defaultPagination?.rowsPerPage || 50,
    order: defaultSorting?.order || 'desc',
    orderBy: defaultSorting?.orderBy || 'createdAt',
    rowsPerPageOptions: defaultPagination?.rowsPerPageOptions || [50, 100],
  });

  const [filters, _setFilters] = useState({});
  const [filterEnabled, setFilterEnabled] = useState(false);

  const setFilters = (newFilters) => {
    _setFilters(newFilters);
    if (
      Object.keys(newFilters).length === 0 ||
      Object.values(newFilters).every(
        (v) => v === '' || v === null || v === undefined || v === false || v === 0 || v?.length === 0
      )
    ) {
      setFilterEnabled(false);
    } else {
      setFilterEnabled(true);
    }
  };

  const handleRequestSort = (event, property) => {
    if (type === 'remote') return handleRequestSortRemote(event, property);
    return handleRequestSortLocal(event, property);
  };
  const handleRequestSortRemote = (event, property) => {
    const isAsc = pagination.orderBy === property && pagination.order === 'asc';

    setPagination({
      ...pagination,
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    });
  };
  const handleRequestSortLocal = (event, property) => {
    const isAsc = pagination.orderBy === property && pagination.order === 'asc';

    setPagination({
      ...pagination,
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    });
  };

  const handleFilterChange = (name, value) => {
    if (type === 'remote') return handleFilterChangeRemote(name, value);
    return handleFilterChangeLocal(name, value);
  };
  const handleFilterChangeLocal = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };
  const handleFilterChangeRemote = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  useEffect(() => {
    if (type === 'remote' && fetchUrl) loadData();
  }, [fetchUrl, pagination, filters]);

  useEffect(() => {
    if (type === 'local' && localData.length > 0) {
      const dataFiltered = applyFilter({
        inputData: localData,
        comparator: getComparator(pagination.order, pagination.orderBy),
        filters,
      });

      setData({
        ...data,
        docs: dataFiltered.slice(
          pagination.page * pagination.rowsPerPage,
          pagination.page * pagination.rowsPerPage + pagination.rowsPerPage
        ),
      });
    }
  }, [localData, pagination, filters]);

  const loadData = async () => {
    try {
      setLoading(true);
      const result = await axios.get(fetchUrl, {
        params: {
          ...pagination,
          page: pagination.page + 1,
          limit: pagination.rowsPerPage,
          filter: JSON.stringify(filters),
        },
      });
      setData(result.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const loadLocalData = (localData) => {
    setLoading(true);
    setLocalData(localData);
    setData({ docs: localData, totalDocs: localData.length });
    setLoading(false);
  };

  const value = {
    data,
    loadData,
    loadLocalData,
    loading,
    pagination,
    setPagination,
    filters,
    setFilters,
    filterEnabled,
    handleRequestSort,
    handleFilterChange,
  };

  return <TableContext.Provider value={value}>{children}</TableContext.Provider>;
};

export const PageWrapper = ({ fetchUrl, page }) => <TableProvider fetchUrl={fetchUrl}>{page}</TableProvider>;

PageWrapper.propTypes = {
  fetchUrl: PropTypes.string.isRequired,
  page: PropTypes.node.isRequired,
};

TableProvider.propTypes = {
  fetchUrl: PropTypes.string,
  children: PropTypes.node.isRequired,
  defaultSorting: PropTypes.shape({
    order: PropTypes.oneOf(['asc', 'desc']),
    orderBy: PropTypes.string,
  }),
  defaultPagination: PropTypes.shape({
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  }),
};
