import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export const formatDuration = (duration, format = 'default') => {
  if (!duration) {
    return `0s`;
  }
  if (duration > 60) {
    const minutes = Math.floor(duration / 60);
    const seconds = duration - minutes * 60;
    if (format === 'no-seconds') return `${minutes}min`;
    return `${minutes}m ${seconds.toFixed(0)}s`;
  }
  return `${duration.toFixed(0)}sec`;
};
