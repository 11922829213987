/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { Table, TableProvider, useTableContext } from '../../../components/table';
import Label from '../../../components/label';

import { mapMasteryLevel, removeNumbers } from '../../../utils/strings';
import { defaulSubjectIconName, subjectNameToIconNameMap } from '../../../utils/subjects';

const SubjectsDetailsTableComponent = ({ subjects }) => {
  const { loadLocalData } = useTableContext();

  useEffect(() => {
    loadLocalData(subjects);
  }, [subjects]);

  const columns = [
    {
      id: 'name',
      label: 'Name',
      renderCell: (row) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={`/assets/icons/subjects/${
              subjectNameToIconNameMap[removeNumbers(row.name).trim()] || defaulSubjectIconName
            }.png`}
            alt={`SAT Decoded App`}
            style={{ height: 40, marginRight: 10 }}
          />
          <Typography variant="body1">{removeNumbers(row.name)}</Typography>
        </Box>
      ),
    },
    {
      id: 'satMasteryLevel',
      label: 'Diagnostic SAT Level -> After Practice Level',
      renderCell: (row) => (
        <>
          <Label color={row.satMasteryLevel === 0 ? 'success' : row.satMasteryLevel === 1 ? 'warning' : 'error'}>
            {mapMasteryLevel(row.satMasteryLevel)}
          </Label>
          {' -> '}
          <Label
            color={row.currentMasteryLevel === 0 ? 'success' : row.currentMasteryLevel === 1 ? 'warning' : 'error'}
          >
            {mapMasteryLevel(row.currentMasteryLevel)}
          </Label>
        </>
      ),
    },
  ];
  return (
    <Stack>
      <Table
        columns={columns}
        hover
        hideMenuButton
        hidePagination
        filtersConfig={[{ name: 'name', label: 'Name', type: 'search' }]}
        rowStyle={() => ({
          '& td': {
            padding: 3,
          },
        })}
      />
    </Stack>
  );
};

export default function SubjectsDetailsTable(props) {
  return (
    <TableProvider>
      <SubjectsDetailsTableComponent {...props} />
    </TableProvider>
  );
}

SubjectsDetailsTableComponent.propTypes = {
  subjects: PropTypes.array,
};
