import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// icons
import { Icon } from '@iconify/react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const Iconify = forwardRef(
  ({ icon, width = 20, sx, variant, roundIconPadding, roundIconBackground, ...other }, ref) => {
    if (variant === 'round') {
      const mainColor = other.color?.includes('.') ? other.color.split('.')[0] : other.color ? sx.color : 'primary';
      sx = {
        borderRadius: '50%',
        backgroundColor: (theme) => roundIconBackground || theme.palette[mainColor].lighter,
        padding: roundIconPadding || 1.2,
        ...sx,
      };
    }
    return <Box ref={ref} component={Icon} icon={icon} sx={{ width, height: width, ...sx }} {...other} />;
  }
);

Iconify.propTypes = {
  sx: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  variant: PropTypes.oneOf(['', 'round']),
  roundIconPadding: PropTypes.number,
  roundIconBackground: PropTypes.string,
};

export default Iconify;
