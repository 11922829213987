import _ from 'lodash';
import ReactApexChart from 'react-apexcharts';
import { useState } from 'react';
import PropTypes from 'prop-types';

// mui
import { Box, Button, Card, CardContent, CardHeader, Divider, Grow, Stack, Typography, useTheme } from '@mui/material';

// components
import Iconify from '../../../components/iconify/Iconify';
import Modal from '../../../components/Dialog';
import { useChart } from '../../../components/chart';

// import { formatDuration } from '../../../utils/formatTime';
import SubjectsDetails from './SubjectsDetailsTable';

export default function SubjectsChart({ data, title, hideDetailsButton, growInTimeout }) {
  const theme = useTheme();

  const [showModal, setShowModal] = useState(false);

  const options = useChart({
    colors: [theme.palette.success.main, theme.palette.warning.main, theme.palette.error.main],
    chart: {},
    legend: {
      show: false,
    },
    labels: ['Beginner', 'Intermediate', 'Master'],
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 0.88,
        },
      },
    },
  });

  const groupped = _.groupBy(data, 'currentMasteryLevel');
  const series = Object.keys(groupped).map((key) => groupped[key].length);

  return (
    <>
      <Grow in timeout={growInTimeout || 1500}>
        <Card>
          <CardHeader title={title || 'Subjects'} />
          <CardContent>
            <Stack>
              <ReactApexChart
                series={series}
                options={{ ...options }}
                height={hideDetailsButton ? 400 : 335}
                type="donut"
              />
              <Stack flexDirection={'row'} gap={2} mt={2} justifyContent={'space-evenly'}>
                <Stack direction={'row'} gap={1} alignItems={'center'}>
                  <Box
                    sx={{
                      height: 10,
                      width: 10,
                      borderRadius: 5,
                      backgroundColor: theme.palette.success.main,
                    }}
                  />
                  <Typography variant="body2">Beginner - {groupped[0]?.length || 0}</Typography>
                </Stack>
                <Stack direction={'row'} gap={1} alignItems={'center'}>
                  <Box
                    sx={{
                      height: 10,
                      width: 10,
                      borderRadius: 5,
                      backgroundColor: theme.palette.warning.main,
                    }}
                  />
                  <Typography variant="body2">Intermediate - {groupped[1]?.length || 0}</Typography>
                </Stack>
                <Stack direction={'row'} gap={1} alignItems={'center'}>
                  <Box
                    sx={{
                      height: 10,
                      width: 10,
                      borderRadius: 5,
                      backgroundColor: theme.palette.error.main,
                    }}
                  />
                  <Typography variant="body2">Master - {groupped[2]?.length || 0}</Typography>
                </Stack>
              </Stack>
            </Stack>
          </CardContent>
          {!hideDetailsButton && (
            <>
              <Divider sx={{ borderStyle: 'dashed' }} />

              <Stack sx={{ p: 2 }} justifyContent={'flex-end'} direction={'row'}>
                <Button
                  variant="text"
                  color="inherit"
                  size="small"
                  endIcon={<Iconify icon={'eva:arrow-ios-forward-outline'} width={18} />}
                  onClick={() => setShowModal(true)}
                >
                  Details
                </Button>
              </Stack>
            </>
          )}
        </Card>
      </Grow>

      <Modal open={showModal} handleClose={() => setShowModal(false)} maxWidth="lg">
        <CardHeader
          title={title || 'Subjects'}
          subheader="By default subjects are sorted by SAT importance and student's attention required."
          action={
            <Button
              color="inherit"
              onClick={() => setShowModal(false)}
              startIcon={<Iconify icon="eva:close-outline" color="inherit" />}
              variant="contained"
            >
              Close
            </Button>
          }
        />

        <CardContent
          sx={{
            p: 0,
            mt: 3,
            minHeight: 300,
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            overflow: 'scroll',
          }}
        >
          <SubjectsDetails subjects={data || []} />
        </CardContent>
      </Modal>
    </>
  );
}

SubjectsChart.propTypes = {
  data: PropTypes.array.isRequired,
  hideDetailsButton: PropTypes.bool,
  title: PropTypes.string,
  growInTimeout: PropTypes.number,
};
